import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { CarouselServices } from "../../components/carouselServices";
import { BreakAttach } from "../../components/breakAttach";

import bgPresencial from "../../assets/imgs/2023/Eventos Presenciales.png";
import bgVirtual from "../../assets/imgs/bg_virtual-2.png";
import bgHibrido from "../../assets/imgs/bg_hibrido.png";
import { useTranslation } from "react-i18next";

export const CongresosEventosPage = () => {
  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo(0, 0);
    };
    scrollToTop();
  });
  const {t}=useTranslation()

  return (
    <>
      <Helmet>
        <title>{t('Organización de congresos y eventos - JC Innovation')}</title>
      </Helmet>
      <section className="wrapper_congresos_eventos_page">
        <div className="contain_head_congresos_eventos">
          <div className="sec_head container_am">
            <h6 className="ttls ttl_thin mb-4 c_primary">
              <span>{t('Servicios')}</span>
            </h6>
            <h3 className="ttls ttl_in_home mb-4">
            {t('Organización de congresos y eventos.')} 
            </h3>
          </div>
        </div>
        <div className="contain__congresos_eventos">
          <div className="container-lg">
            <div className="sec_slider_page_events">
              <CarouselServices isPage="_congresos_eventos" />
            </div>
          </div>
        </div>

        <div className="contain_info__congresos presencial">
          <div className="container-lg">
            <div className="row justify-content-between">
              <div className="col-md-5">
                <div className="box_info__services mb-5">
                  <h6 className="ttls ttl_thin mb-3 simple">
                    <span>{t('Eventos corporativos')}</span>
                  </h6>
                  <h3 className="ttls ttl_section mb-5 c_marin">
                  {t('PRESENCIALES:')} 
                  </h3>
                  <p className="paragraph sm">
                  {t('Somos expertos realizando cualquier tipo de evento; desde eventos corporativos, conferencias y congresos, hasta lanzamientos de nuevos productos.')} 
                  </p>
                  <p className="paragraph sm cite mt-5">
                  {t('Organizamos los mejores eventos y le damos la importancia que se merece, no importa el tamaño de tu Congreso.')}  
                  </p>
                  <p className="paragraph sm cite mt-5">
                  {t('Buscamos la mejor opción en tarifas hoteleras, apoyos gubernamentales y más, para asegurar el objetivo de tu marca.')}
                  </p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="box_bg_services text-center">
                  <img
                    src={bgPresencial}
                    alt="Congresos y Eventos Presenciales"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="contain_info__congresos virtual">
          <div className="container-lg">
            <div className="row justify-content-between">
              <div className="col-md-5">
                <div className="box_bg_services mb-2 text-center">
                  <img src={bgVirtual} alt="Congresos y Eventos Virtuales" />
                </div>
              </div>
              <div className="col-md-6">
                <div className="box_info__presencial mb-5">
                  <h6 className="ttls ttl_thin mb-3 simple">
                    <span>{t('Eventos corporativos.')}</span>
                  </h6>
                  <h3 className="ttls ttl_section mb-5 c_primary">{t('VIRTUALES:')}</h3>
                  <p className="paragraph sm">
                  {t('No importa la modalidad, nosotros estamos preparados para cumplir con el objetivo de los eventos.')}
                  </p>
                  <hr />
                  {/* <p className="paragraph sm cite mt-5">
                    Organizamos los mejores eventos y le damos la importancia
                    que se merece, no importa el tamaño de tu Congreso.
                  </p>
                  <p className="paragraph sm  mt-5">
                    Buscamos la mejor opción en tarifas hoteleras, apoyos
                    gubernamentales y más, para asegurar el objetivo de tu
                    marcado.
                  </p> */}
                  <div className="mt-5">
                    <p className="paragraph sm mb-4">
                      <i className="fa-solid c_primary fa-plus"></i> {t('Registro en línea.')}
                    </p>
                    <p className="paragraph sm mb-4">
                      <i className="fa-solid c_primary fa-plus"></i> {t('Streaming.')}
                    </p>
                    <p className="paragraph sm mb-4">
                      <i className="fa-solid c_primary fa-plus"></i> {t('Expo comercial.')}
                    </p>
                    <p className="paragraph sm mb-4">
                      <i className="fa-solid c_primary fa-plus"></i> {t('Traducción simultanea.')}
                    </p>
                    <p className="paragraph sm mb-4">
                      <i className="fa-solid c_primary fa-plus"></i> {t('Chat.')}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="contain_info__congresos scope">
          <div className="text-center container_am">
            <h6 className="ttls ttl_thin mb-4">
              <span>{t('Llega a más personas')}</span>
            </h6>
            <h3 className="ttls ttl_section mb-4">
            {t('Visualiza el alcance de tu evento presencial y virtual.')} 
            </h3>
            <p className="paragraph lg">
            {t('Dos experiencias personalizadas para cada tipo de asistente.')}
            </p>
          </div>
          <div className="section_compare_events">
            <div className="container_am">
              <div className="row_compare_events">
                <div className="box_details_events ttl__compare">
                  <h4 className="fw_500 c_morado ">{t('Antes del evento')}</h4>
                </div>
                <div className="box_details_events">
                  <h3 className="fw_500 text-center ttl_service_compare">
                    {t('Presencial.')}
                  </h3>
                  <ul>
                    <li>
                      <p className="paragraph sm">{t('Registro en sitio.')}</p>
                    </li>
                    <li>
                      <p className="paragraph sm">{t('Hospedaje.')}</p>
                    </li>
                    <li>
                      <p className="paragraph sm">{t('Apoyos gubernamentales.')}</p>
                    </li>
                    <li>
                      <p className="paragraph sm">
                      {t('Transportación aérea y terrestre.')}
                      </p>
                    </li>
                  </ul>
                </div>
                <div className="box_details_events">
                  <h3 className="fw_500 text-center ttl_service_compare">
                  {t('Virtual.')} 
                  </h3>
                  <ul>
                    <li>
                      <p className="paragraph sm">{t('Registro en línea.')}</p>
                    </li>
                    <li>
                      <p className="paragraph sm">{t('Desarrollo web.')}</p>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="row_compare_events">
                <div className="box_details_events ttl__compare">
                  <h4 className="fw_500 c_morado ">{t('Durante el evento.')}</h4>
                </div>
                <div className="box_details_events">
                  <ul>
                    <li>
                      <p className="paragraph sm">{t('Gestión de eventos.')}</p>
                    </li>
                    <li>
                      <p className="paragraph sm">{t('Expo comercial.')}</p>
                    </li>
                    <li>
                      <p className="paragraph sm">{t('Eventos sociales.')}</p>
                    </li>
                    <li>
                      <p className="paragraph sm">{t('Tour de acompañantes.')}</p>
                    </li>
                    <li>
                      <p className="paragraph sm">{t('Personal bilingue.')}</p>
                    </li>
                    <li>
                      <p className="paragraph sm">{t('Display e impresos.')}</p>
                    </li>
                    <li>
                      <p className="paragraph sm">{t('')}</p>
                    </li>
                    <li>
                      <p className="paragraph sm">{t('Iluminación.')}</p>
                    </li>
                    <li>
                      <p className="paragraph sm">{t('Proyección.')}</p>
                    </li>
                    <li>
                      <p className="paragraph sm">{t('Catering.')}</p>
                    </li>
                  </ul>
                </div>
                <div className="box_details_events">
                  <ul>
                    <li>
                      <p className="paragraph sm">{t('Gestión de evento.')}</p>
                    </li>
                    <li>
                      <p className="paragraph sm">{t('Streaming.')}</p>
                    </li>
                    <li>
                      <p className="paragraph sm">{t('Expo Comercial.')}</p>
                    </li>
                    <li>
                      <p className="paragraph sm">{t('Personal bilingüe.')}</p>
                    </li>
                    <li>
                      <p className="paragraph sm">{t('Soporte en línea.')}</p>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="row_compare_events">
                <div className="box_details_events ttl__compare">
                  <h4 className="fw_500 c_morado ">{t('Después del evento.')}</h4>
                </div>
                <div className="box_details_events">
                  <ul>
                    <li>
                      <p className="paragraph sm">{t('Envío de constancias.')}</p>
                    </li>
                    <li>
                      <p className="paragraph sm">{t('Entrega de métricas.')}</p>
                    </li>
                  </ul>
                </div>
                <div className="box_details_events">
                  <ul>
                    <li>
                      <p className="paragraph sm">{t('Envío de constancias.')}</p>
                    </li>
                    <li>
                      <p className="paragraph sm">{t('Entrega de métricas.')}</p>
                      
                    </li>
                    <li>
                      <p className="paragraph sm">{t('Descarga de constancias.')}</p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="contain_info__congresos virtual">
          <div className="container-lg">
            <div className="row justify-content-between">
              <div className="col-md-5">
                <div className="box_bg_services mb-2 text-center">
                  <img src={bgHibrido} alt="Congresos y Eventos Híbrido" />
                </div>
              </div>
              <div className="col-md-6">
                <div className="box_info__presencial mb-5 mt-4">
                  <h6 className="ttls ttl_thin mb-3 simple">
                    <span>{t('Eventos corporativos')}</span>
                  </h6>
                  <h3 className="ttls ttl_section mb-5 c_primary">{t('HÍBRIDOS:')}</h3>
                  <p className="paragraph sm">
                  {t('Disfruta del evento en forma remota en un ambiente virtual totalmente innovador y si tus invitados asisten de forma presencial, contamos con tarifas preferenciales con distintas aerolíneas a nivel internacional, transportación terrestre incluida y organización de tours de acompañantes y eventos sociales.')} 
                  </p>
                  <div className="row">
                    <div className="col-md-6">
                      <p className="paragraph sm cite mt-5">
                      {t('Crea una experiencia igualmente gratificante para ambos tipos de participantes.')} 
                      </p>
                    </div>
                    <div className="col-md-6">
                      <p className="paragraph sm cite mt-5">
                      {t('La ubicación física de tu evento dejará de ser una limitante y llegarás a más personas.')} 
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <BreakAttach inPage="congresosEventos__page" />
      </section>
    </>
  );
};

import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import candado from "../assets/imgs/candado.png";
import { useTranslation } from "react-i18next";

export const AvisoPage = () => {
  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo(0, 0);
    };
    scrollToTop();
  });
  const settings = {
    arrows: false,
    dots: true,
    fade: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1000,
  };  const {t}=useTranslation()

  return (
    <>
      <Helmet>
        <title>AVISO DE PRIVACIDAD - JC Innovation</title>
      </Helmet>
      <section className="wrapper_page_about">
        <div className="wrapper_home_2">
            <div className="container-lg">
              <div className="sec_content_home2">
                <div className="content_info">
                  <div className="box_info_home">
                    <h3 className="ttls ttl_thin">{t('Nuestro:')}</h3>
                    <h1 className="ttls ttl_in_home  ttl_section_med fw_600">
                      {t('AVISO DE PRIVACIDAD')}
                    </h1>
                  </div>
                  
                    <div className="  wrapper_aviso" >
                      <a href="#aviso">
                        <img src={candado} alt="Candado" />
                      </a>
                    </div>
                  
                </div>
              </div>
            </div>
          </div>
          
          <div className="contain_mis_aviso ">
            <div className="container-lg ">
              <div className="row justify-content-center">
                <div className="col-md-6">
                  <div className="box_mis_vis">
                    <h3 className="ttls ttl_section mb-4" id="aviso">{t('AVISO DE PRIVACIDAD')}</h3>
                    <p class="card-text"><small class="text-muted">{t('Autor:')} JC-Innovation</small></p>
                    <p className="paragraph sm">{t('Fecha:')}  Agosto 12, 2020 </p>
                    <p className="paragraph sm texto_justificado">
                   {t(' De conformidad con lo establecido en la Ley Federal de Protección de Datos Personales en Posesión de los Particulares,')}<strong>JC Innovation S.A. de C.V.</strong>{t('pone a su disposición el siguiente aviso de privacidad.')}  </p>
                    <p className="paragraph sm texto_justificado">
                    <strong>{t('')}JC Innovation S.A. de C.V.</strong>{t(', es responsable del uso y protección de sus datos personales, en este sentido y atendiendo las obligaciones legales establecidas en la Ley Federal de Protección de Datos Personales en Posesión de los Particulares, a través de este instrumento se informa a los titulares de los datos, la información que de ellos se recaba y los fines que se le darán a dicha información.')}
                    </p>
                    <p className="paragraph sm texto_justificado">{t(' Además de lo anterior, informamos a usted que')}
                    <strong>{t('')}JC Innovation S.A. de C.V.</strong>{t('tiene su domicilio ubicado en:')},  {"\n"}
                    JARDINES DE LA HACIENDA NORTE, 54729 CUAUTITLAN IZCALLI, Méx.
                    </p>
                    <p className="paragraph sm texto_justificado">{t('Los datos personales que recabamos de usted serán utilizados para las siguientes finalidades, las cuales son necesarias para concretar nuestra relación con usted así como atender los servicios y/o pedidos que solicite:')}

                    </p>
                    <p className="paragraph sm texto_justificado">{t(' I) Para contactarlo y enviarle información relevante sobre nuestros productos y/o servicios.')}
                   </p>
                  <p className="paragraph sm texto_justificado">{t(' II) Identificarlo como cliente y/o proveedor de JC Innovation S.A. de C.V.')}</p>
                  <p className="paragraph sm texto_justificado">{t('III) Para servicios de registro de asistentes a congresos, conferencias, exposiciones, ferias, convenciones y en general cualquier reunión, a todos los visitantes generales.')}</p>
                  <p className="paragraph sm texto_justificado">{t('IV)Envío de publicidad, encuestas.')}  </p>
                    
                    <p class="paragraph sm texto_justificado">{t('  Para llevar a cabo las finalidades descritas en el presente aviso de privacidad, utilizaremos los siguientes datos personales: Nombre, E-mail, teléfono, celular, domicilio completo, nacionalidad, curp, rfc, genero, fecha de nacimiento, intereses, uso de cookies, web beacons.')}
              
                </p>
              <p class="paragraph sm texto_justificado">{t(' Además de estos datos personales, se hará uso de los siguientes datos que conforme a ley son considerados como datos personales sensibles, por lo cual requieren de su autorización expresa y por escrito para poder hacer uso de ellos. En este sentido, nos comprometemos con usted a no realizar ningún tipo de uso o tratamiento de sus datos personales sensibles, en tanto no se recabe la autorización firmada por escrito o a través de un sistema de firma electrónica, en el que usted nos otorgue expresamente el consentimiento para tratar sus siguientes datos personales sensibles: Estado de salud, información genética, creencias religiosas, preferencia sexual, tarjetas de crédito o débito, contraseñas.')}
               
              </p>
              <p class="paragraph sm texto_justificado">{t('Por otra parte, informamos a usted, que sus datos personales no serán compartidos con ninguna autoridad, empresa, organización o persona distintas a nosotros y serán utilizados exclusivamente para los fines señalados.')}
              </p>
              <p class="paragraph sm texto_justificado">{t('Usted tiene en todo momento el derecho a conocer qué datos personales tenemos de usted, para qué los utilizamos y las condiciones del uso que les damos (Acceso). Asimismo, es su derecho solicitar la corrección de su información personal en caso de que esté desactualizada, sea inexacta o incompleta (Rectificación); de igual manera, tiene derecho a que su información se elimine de nuestros registros o bases de datos cuando considere que la misma no está siendo utilizada adecuadamente (Cancelación); así como también a oponerse al uso de sus datos personales para fines específicos (Oposición). Estos derechos se conocen como derechos ARCO.')}
              </p>
              <p class="paragraph sm texto_justificado">{t('Para el ejercicio de cualquiera de los derechos ARCO, se deberá presentar la solicitud respectiva a través del siguiente correo electrónico:')}
                {"\n"}
                contacto@jc-innovation.com
              </p>
              <p class="paragraph sm texto_justificado">{t(' Lo anterior también servirá para conocer el procedimiento y requisitos para el ejercicio de los derechos ARCO, no obstante, la solicitud de ejercicio de estos derechos debe contener la siguiente información:')}
               {t(' Nombre, email y motivo de cancelación.')}
               
              </p>
              <p class="paragraph sm texto_justificado">{t('La respuesta a la solicitud se dará en máximo 15 días hábiles y se comunicará de la siguiente manera:')}
                </p>
                <p class="paragraph sm texto_justificado">{t(' A través del correo electrónico que indique la solicitud.')}
              </p>
              <p class="paragraph sm texto_justificado">{t(' Los datos de contacto de la persona o departamento de datos personales, que está a cargo de dar trámite a las solicitudes de derechos ARCO, son los siguientes:')}
               
              </p>
              <p class="paragraph sm texto_justificado">{t('a) Nombre del responsable: Departamento de sistemas ')}
                </p>
                <p class="paragraph sm texto_justificado">{t(' b) JARDINES DE LA HACIENDA NORTE, 54729 CUAUTITLAN IZCALLI, Méx.')}</p>
                <p class="paragraph sm texto_justificado">{t(' c) Teléfono: (55) – 62 34 55 58  /  (55) - 89 03 75 97 ')}</p>
                <p class="paragraph sm texto_justificado">{t(' d) Correo electrónico: contacto@jc-innovation.com')}</p>
                <p class="paragraph sm texto_justificado">{t(' e) Otro medio de contacto: A través del sitio ')}<a href="https://jc-innovation.com/contacto">https://jc-innovation.com/contacto</a>
              </p>
              <p class="paragraph sm texto_justificado">{t('Cabe mencionar, que en cualquier momento usted puede revocar su consentimiento para el uso de sus datos personales. Del mismo modo, usted puede revocar el consentimiento que, en su caso, nos haya otorgado para el tratamiento de sus datos personales. Sin embargo, es importante que tenga en cuenta que no en todos los casos podremos atender su solicitud o concluir el uso de forma inmediata, ya que es posible que por alguna obligación legal requiramos seguir tratando sus datos personales. Asimismo, usted deberá considerar que para ciertos fines, la revocación de su consentimiento implicará que no le podamos seguir prestando el servicio que nos solicitó, o la conclusión de su relación con nosotros.')}
                
              </p>
              <p class="paragraph sm texto_justificado">{t(' Para revocar el consentimiento que usted otorga en este acto o para limitar su divulgación, se deberá presentar la solicitud respectiva a través del siguiente correo electrónico:')}
               {"\n"}
                contacto@jc-innovation.com
              </p>
              <p class="paragraph sm texto_justificado">{t('Del mismo modo, podrá solicitar la información para conocer el procedimiento y requisitos para la revocación del consentimiento, así como limitar el uso y divulgación de su información personal, sin embargo, estas solicitudes deberán contener la siguiente información:')} {"\n"}
              {t('Nombre, E-mail y motivo de cancelación.')} 
                
              </p>
              <p class="paragraph sm texto_justificado">{t('La respuesta a la solicitud de revocación o limitación de divulgación de sus datos se dará a más tardar en máximo 15 días hábiles y se comunicará de la siguiente forma: A través del correo electrónico indicado en la solicitud')}
                
              </p>
              <p class="paragraph sm texto_justificado">{t(' El presente aviso de privacidad puede sufrir modificaciones, cambios o actualizaciones derivadas de nuevos requerimientos legales; de nuestras propias necesidades por los productos o servicios que ofrecemos; de nuestras prácticas de privacidad; de cambios en nuestro modelo de negocio, o por otras causas, por lo cual, nos comprometemos a mantenerlo informado sobre los cambios que pueda sufrir el presente aviso de privacidad, sin embargo, usted puede solicitar información sobre si el mismo ha sufrido algún cambio a través del siguiente correo electrónico: contacto@jc-innovation.com')}
               
              </p>
                  </div>
                </div>
                
                
              </div>
            </div>
          </div>

        
      </section>
    </>
  );
};

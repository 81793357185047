import React from "react";
import './experienceData.scss'
import { useTranslation } from "react-i18next";

export const ExperienceData = () => {
  const {t}=useTranslation()
  return (
    <div className="wrapper_exp_data">
      <div className="container-lg">
        <div className="content_exp_data">
          <div className="item_exp_data">
            <span className="data_big">+15</span>
            <span className="data_text">{t('Años de experiencia.')}</span>
          </div>
          <div className="item_exp_data">
            <span className="data_big">+576</span>
            <span className="data_text">{t('Clientes satisfechos.')}</span>
          </div>
          <div className="item_exp_data">
            <span className="data_big">+2465</span>
            <span className="data_text">{t('Proyectos exitosos.')}</span>
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { useEffect } from "react";
import '../../assets/themes/viajes.css'
import { Helmet } from "react-helmet";
import { CarouselServices } from "../../components/carouselServices";
import { BreakAttach } from "../../components/breakAttach";

import viajeFront from "../../assets/imgs/2023/viajes/frontViaje.png";
import bgStreamingContent from "../../assets/imgs/servicios/streaming/str05.jpg";
import ico1 from "../../assets/imgs/2023/viajes/viajes (7).png"
import ico2 from "../../assets/imgs/2023/viajes/viajes (8).png"
import ico3 from "../../assets/imgs/2023/viajes/viajes (9).png"
import icoPdcst01 from "../../assets/imgs/2023/viajes/ico (1).png";
import icoPdcst02 from "../../assets/imgs/2023/viajes/ico (2).png";
import icoPdcst03 from "../../assets/imgs/2023/viajes/ico (3).png";
import icoPdcst04 from "../../assets/imgs/2023/viajes/ico (4).png";

import { useTranslation } from "react-i18next";
const Viajes = () => {
  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo(0, 0);
    };
    scrollToTop();
  });
  const {t}=useTranslation()
  return (
    <>
      <Helmet>
        <title>Viajes - JC Innovation</title>
      </Helmet>
      <section className="wrapper__page_services">
        <div className="contain__head_page">
          <div className="container-lg">
            <div className="row">
              <div className="col-md-6">
                <div className="sec_head container_am">
                  <h6 className="ttls ttl_thin mb-4 c_primary">
                    <span>{t('Servicios')} </span>
                  </h6>
                  <h3 className="ttls ttl_in_home mb-4">{t('Agencia de Viajes')}</h3>
                  <p className="paragraph lg">
                  {t('Tu destino premium para hospedaje y viajes hacia tus congresos, eventos y exposiciones. Experiencias inolvidables, servicio excepcional.')} 
                  </p>
                  <div className="mt-5 text-start contenIconsViajes" >
                    <img src={ico1} />
                    <img src={ico2} />
                    <img src={ico3} />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="text-center">
                  <img src={viajeFront} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="contain__slide_page">
          <div className="container_am">
            <div className="text-center ">
              <h3 className="ttls ttl_section mb-2">
              {t(' Ahorra tiempo y dinero: comodidad y los mejores precios para ')}
                <span className="c_morado">{t('tu negocio y tus clientes')} </span>
              </h3>
              <p className="paragraph lg">
              {t('Experimenta la perfección en cada detalle a donde quiera que vayas.')}
              </p>
            </div>
          </div>
          <div className="container-lg">
            <div className="sec_slider_page_events">
              <CarouselServices isPage="_viajes" />
            </div>
          </div>
        </div>
        <div className="contain_info__page  ">
          <div className="container-lg">
            <div className="row gx-5 customServicesTrabels">
              <div className="col-md-4 col-sm-6 mb-3">
                <div>
                  <div className="icoinpods">
                    <img src={icoPdcst01} alt="Podcast JC Innovation" />
                  </div>
                  <h2 className="ttls mb-4 fw_700">
                  {t('Comodidad y')}<span className="c_morado"> {t(' Confort.')}</span>
                  </h2>
                  <hr />
                  <p className="paragraph sm fw_300">
                    {t('Hacemos que cada experiencia sea digna de memorar dentro de los mejores complejos hoteleros.')} 
                  </p>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 mb-3">
                <div>
                <div className="icoinpods">
                    <img src={icoPdcst02} alt="Podcast JC Innovation" />
                  </div>
                  <h2 className="ttls mb-4 fw_700">
                    <span className="c_morado"> {t('Modelo de ')}</span>{t('Funcionamiento.')}
                  </h2>
                  <hr />
                  <p className="paragraph sm fw_300">
                    {t('Tú mismo puedes elegir qué modelo de funcionamiento te conviene más: precios netos o modelos de comisión.')} 
                  </p>
                </div>
              </div>
             
              <div className="col-md-4 col-sm-6 mb-3">
                <div>
                  <div className="icoinpods">
                    <img src={icoPdcst03} alt="Podcast JC Innovation" />
                  </div>
                  <h2 className=" mb-4 fw_700">
                  {t('Transporte')}  <span className="c_morado">{t('Terrestre y Aéreo.')}</span>
                  </h2>
                  <hr />
                  <p className="paragraph sm fw_300">
                  {t('La transportación terrestre y aérea requiere una logística a través de una planificación de rutas eficientes y eficaces.')}
                  </p>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 mb-3">
                <div>
                  <div className="icoinpods">
                    <img src={icoPdcst04} alt="Podcast JC Innovation" />
                  </div>
                  <h2 className=" mb-4 fw_700">
                  <span className="c_morado">{t('Traslados  ')}</span> {t('a tu Alcance.')} 
                  </h2>
                  <hr />
                  <p className="paragraph sm fw_300">
                  {t('Servicios de transporte, Traslados privados al aeropuerto de puerta a puerta, Transfers Compartidos al Aeropuerto. ')}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        {/* <BreakAttach inPage="streaming__page" /> */}
      </section>
    </>
  );
}

export default Viajes

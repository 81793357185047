import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Slider from "react-slick";

import { ItemTeams } from "../components/itemTeams/itemTeams";
import { useTranslation } from "react-i18next";

export const BolsaPage = () => {
  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo(0, 0);
    };
    scrollToTop();
  });
  const settings = {
    arrows: false,
    dots: true,
    fade: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1000,
  };
  const {t}=useTranslation()

  return (
    <>
      <Helmet>
        <title>Bolsa de trabajo - JC Innovation</title>
      </Helmet>
      <section className="wrapper_page_about">
      <div className="wrapper_home_3">
          <div className="container-lg">
            <div className="sec_content_home">
              <div className="content_info">
                <div className="box_info_home">
                  <h1 className="ttls ttl_in_home  ttl_section_med fw_600">
                    {t('BOLSA DE TRABAJO')} 
                  </h1>
                  <h4 className="ttls ttl_section mb-4">{t('Datos de contacto')}</h4>
                  <p className="paragraph sm">{t('Correo')}:recursoshumanos@jc-innovation.com</p>
                  <p className="paragraph sm"> {t('Telefono')}:(558) 903 7597</p>
                  <p className="paragraph sm">
                  {t('Manda tu CV con tus datos de contacto y puesto al que deseas ingresar.')}</p>
                  <p className="paragraph sm texto_justificado">
                  <strong>JC Innovation S.A. de C.V.</strong>{t(', es responsable del uso y protección de sus datos personales, en este sentido y atendiendo las obligaciones legales establecidas en la Ley Federal de Protección de Datos Personales en Posesión de los Particulares, a través de este instrumento se informa a los titulares de los datos, la información que de ellos se recaba y los fines que se le darán a dicha información. ')}
                  </p>
                  
                  <Link to="/aviso" >
                <div className="w-100">
                    <p className="paragraph mb-0">{t('CONOCE NUESTRO AVISO DE PRIVACIDAD')}</p>
                 </div>
                   </Link>
                 
                </div>
              </div>
            </div>
          </div>
        </div>
        
        

        
      </section>
    </>
  );
};

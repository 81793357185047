import React, {useEffect, useRef,useState} from "react";
import { Helmet } from "react-helmet";
import bg_contact from "../assets/imgs/bg_contact.jpg";
import Swal from 'sweetalert2';
import { useTranslation } from "react-i18next";



export const  ContactPage = () => {
   useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo(0, 0);
    }
    scrollToTop()
  })
  const form = useRef();
  const [loading, setLoading] = useState(false);
  const [quote, setQuote] = useState({});
  const [mobile, setmobile] = useState("");
  const [isError, setIsError] = useState(false);
  const sendEmail = (e) => {
    Swal.fire("Mensaje Enviado","Correctamente","success");
    setLoading(true);
    e.preventDefault();
    console.log(e.target.name.value);
    var nombre = e.target.name.value;
    var email=e.target.email.value;
    var phone = e.target.phone.value;
    var message = e.target.message.value;
    var datos = {nombre:nombre, email:email, telefono:phone,mensaje:message}
 
    let url = 'enviar.php';
    fetch(url, {
      method:'POST',
      body:JSON.stringify(datos)
    })
    .then(res=>res.json())
    .then(json=>{
      if(json == 'ok'){
       Swal.fire("Mensaje Enviado","Correctamente","success");
      }else{
       Swal.fire('Mensaje Erroneo','Intentelo de Nuevo Por Favor', 'error');
      }
    })
    e.currentTarget.reset();
    setLoading(false);
  };
  const {t}=useTranslation()

  
  return (
    <>
      <Helmet>
        <title>Contacto - JC Innovation</title>
      </Helmet>
      <section className="wrapper_page_contact">
        <div className="contain_head_contact">
            <div className="sec_head container_am">
              <h6 className="ttls ttl_thin mb-4 c_primary">
                <span>{t('CONTÁCTENOS')}</span>
              </h6>
              <h3 className="ttls ttl_section_med mb-4">
              {t('¿Tienes una idea para tu evento?')}
              </h3>
              <p className="paragraph lg">
              {t('Si tienes alguna duda o comentario, ¡contáctanos! Estamos seguros que podemos ayudarte en los proyectos que tienes en mente.')}
              </p>
            </div>
        </div>
        <div className="contain_bg_data_business">
          <div className="container-lg">
            <div className="row">
              <div className="col-md-4 col-sm-6">
                <div className="item_contact_head mb-4">
                  <div>
                    <h1>
                      <i className="fa-regular fa-envelope"></i>
                    </h1>
                    <h5>
                      <a href="mailto:contacto@jc-innovation.com">
                  contacto@jc-innovation.com
                      </a>
                    </h5>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-6">
                <div className="item_contact_head mb-4">
                  <div>
                    <h1>
                      <i className="fa-solid fa-phone"></i>
                    </h1>
                    <h5>
                      <a href="tel:+5565865748">(556) 586 5748</a>
                    </h5>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-6">
                <div className="item_contact_head mb-4">
                  <div>
                    <h1>
                      <i className="fa-solid fa-location-dot"></i>
                    </h1>
                    <h5>
                      <a target="_blank"
                        title="Linkedin"
                        rel="nofollow noopener noreferrer"
                        href="https://www.google.com/maps/place/World+Trade+Center+Mexico+City/@19.3936599,-99.1771628,17z/data=!3m1!5s0x85d1ff71986b261b:0x9959045f882ded13!4m10!1m2!2m1!1sWTC.+Piso12+oficina+19!3m6!1s0x85d1ff718f4416e1:0x6e35698f0852629c!8m2!3d19.3936599!4d-99.1745879!15sChdXVEMuIFBpc28gMTIgb2ZpY2luYSAxOZIBD2J1c2luZXNzX2NlbnRlcuABAA!16zL20vMDRraHdr">
                        Montecito 38, Piso 12, Nápoles, Benito Juárez, 03810 Ciudad de México, CDMX
                      </a>
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="contain_in_contact">
          {loading ? (
            <div className="loader-container">
              <div className="spinner"></div>
            </div>
          ) : (
          <div className="container-lg">
            <div className="row">
              <div className="col-sm-6">
                <div className="sec_form_contact">
                  <h3 className="ttls ttl_section ">
                  {t('Únete a nosotros y permítenos ayudarte a poner en marcha tu idea.')} 
                  </h3>
                  <form ref={form} onSubmit={sendEmail}>
                    <div className="mb-3">
                      <label for="name" className="form-label">
                      {t('NOMBRE COMPLETO')}   
                      </label>
                      <input type="name" className="form-control" id="name" name="name" required/>
                    </div>
                    <div className="mb-3">
                      <label for="phone" className="form-label">
                      {t('TELÉFONO')} 
                      </label>
                      <input type="tel" className="form-control" id="phone" name="phone" pattern="[0-9]{10}" required/>
                    </div>
                    <div className="mb-3">
                      <label for="email" className="form-label">
                      {t('CORREO ELECTRÓNICO')} 
                      </label>
                      <input type="email" className="form-control" id="email" name="email" required/>
                    </div>
                    <div className="mb-3">
                      <label
                        for="exampleFormControlTextarea1"
                        className="form-label"
                      >
                       {t('MENSAJE')} 
                      </label>
                      <textarea className="form-control" name="message" id="message" rows="3"></textarea>
                    </div>
                    <button type="submit" className="btn btn-primary w-100">
                    {t('ENVIAR MENSAJE')}   <i className="fa-solid fa-paper-plane"></i>
                    </button>
                    
                  </form>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="sec_bg_contact">
                  <img src={bg_contact} alt="Contacto JC Innovation" />
                </div>
              </div>
            </div>
          </div>
          )
        }
        </div>
      </section>
    </>
  );
  
};
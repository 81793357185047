import React from "react";
import Carousel, {
  slidesToShowPlugin,
  autoplayPlugin,
} from "@brainhubeu/react-carousel";
import "@brainhubeu/react-carousel/lib/style.css";
import "./upcomingEvents.scss";

import AMMR from "../../assets/imgs/upcoming-events/2024/AMMR.jpg";
import SOCMEXCIRPED from "../../assets/imgs/upcoming-events/2024/SOCMEXCIRPED.jpg";
import FENASTAC from "../../assets/imgs/upcoming-events/2024/Fenastac.jpg";
import DISPOSITIVOS from "../../assets/imgs/upcoming-events/2024/Dispositivos Medicos.jpg";
import EMERGENCY from "../../assets/imgs/upcoming-events/2024/EPT.jpg";
import GINECO from "../../assets/imgs/upcoming-events/2024/Gineco.jpg";
import MEXCTRIMS from "../../assets/imgs/upcoming-events/2024/Mextrims.jpg";
import NEUROLOGIA from "../../assets/imgs/upcoming-events/2024/Neuro.jpg";
import { useTranslation } from "react-i18next";



export const UpcomingEvents = () => {
  const {t}=useTranslation()
  return (
    <div className="wrapper_upcoming text-center ">
      <div className="container-lg">
        <div className="content_upcoming">
          <div className="box_info__upcoming">
            <h6 className="ttls ttl_thin mb-4">
              <span>{t('Calendario')}</span>
            </h6>
            <h3 className="ttls ttl_section mb-4">{t('Próximos eventos')}</h3>
            <p className="paragraph lg">
            {t('Conoce los próximos eventos que estamos organizando.')}
            </p>
          </div>
          <Carousel
            plugins={[
              "centered",
              "infinite",
              "arrows",
              {
                resolve: slidesToShowPlugin,
                options: {
                  numberOfSlides: 3,
                },
              },
              {
                resolve: autoplayPlugin,
                options: {
                  interval: 1250,
                },
              },
            ]}
            breakpoints={{
              480: {
                plugins: [
                  "centered",
                  "infinite",
                  "arrows",
                  {
                    resolve: slidesToShowPlugin,
                    options: {
                      numberOfSlides: 1,
                    },
                  },
                  {
                    resolve: autoplayPlugin,
                    options: {
                      interval: 1250,
                    },
                  },
                ],
              },
              1200: {
                plugins: [
                  "centered",
                  "infinite",
                  "arrows",
                  {
                    resolve: slidesToShowPlugin,
                    options: {
                      numberOfSlides: 2,
                    },
                  },
                  {
                    resolve: autoplayPlugin,
                    options: {
                      interval: 1250,
                    },
                  },
                ],
              },
            }}
            animationSpeed={500}
          >
           <div className="item_upcoming">
<img src={AMMR} alt={t('PRÓXIMOS EVENTOS JC INNOVATION')} />
</div>
<div className="item_upcoming">
<img src={SOCMEXCIRPED} alt={t('PRÓXIMOS EVENTOS JC INNOVATION')} />
</div>
<div className="item_upcoming">
<img src={FENASTAC} alt={t('PRÓXIMOS EVENTOS JC INNOVATION')} />
</div>
<div className="item_upcoming">
<img src={DISPOSITIVOS} alt={t('PRÓXIMOS EVENTOS JC INNOVATION')} />
</div>
<div className="item_upcoming">
<img src={EMERGENCY} alt={t('PRÓXIMOS EVENTOS JC INNOVATION')} />
</div>
<div className="item_upcoming">
<img src={GINECO} alt={t('PRÓXIMOS EVENTOS JC INNOVATION')} />
</div>
<div className="item_upcoming">
<img src={MEXCTRIMS} alt={t('PRÓXIMOS EVENTOS JC INNOVATION')} />
</div>
<div className="item_upcoming">
<img src={NEUROLOGIA} alt={t('PRÓXIMOS EVENTOS JC INNOVATION')} />
</div>
            
          </Carousel>
        </div>
      </div>
    </div>
  );
};

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import jsonESPAÑOL from './jsonTraducciones/ESPAÑOL.json'
import jsonINGLES from './jsonTraducciones/INGLES.json'


i18n.use(initReactI18next).init({
  resources: {
    es: {
      translation: jsonESPAÑOL
    },
    en: {
      translation:jsonINGLES,
    },
    // Agrega más idiomas y traducciones según sea necesario
  },
  lng:"es", // Define el idioma predeterminado
  fallbackLng: "es", // Define el idioma de respaldo si no se encuentra una traducción
  interpolation: {
    escapeValue: false, // Habilita la interpolación de variables en las traducciones
  },
});

export default i18n;